import React from "react"
import Layout from "./src/components/PageLayout/Layout"
import "./src/styles/variables.scss"
import "./src/styles/global.scss"
import "./src/styles/pages/index.scss"
import "./src/styles/pages/single.scss"

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
