import { useStaticQuery, graphql } from "gatsby"

export const useOrderedWpPostsQuery = () => {
  const data = useStaticQuery(graphql`
    query allOrderedPosts {
      wpPage(slug: { eq: "works" }) {
        id
        works {
          allProjects {
            ... on WpPost {
              id
              uri
              id
              title
              date(formatString: "Y")
              project {
                labels {
                  label
                }
                prepend
              }
            }
          }
        }
      }
    }
  `)

  return data
}
