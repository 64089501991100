import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import "./Sidebar.scss"

const movements = {
  initial: {
    x: "-100%",
    opacity: 0,
  },
  animate: {
    x: "0%",
    opacity: 1,
  },
  exit: {
    x: "-100%",
    opacity: 0,
  },
}

const Sidebar = ({ data, sidebarState, width, currentIndex }) => {
  return (
    <>
      <motion.div
        className="sidebar"
        variants={movements}
        initial="initial"
        animate={sidebarState ? "animate" : "exit"}
        transition={{ type: "tween", duration: 0.3 }}
      >
        <div className="sidebar-links-wrapper">
          <div className="title-label">
            {data.wpPage.works.allProjects?.map((work, index) => {
              return (
                <Link
                  className={`sidebar-link ${index === 0 ? "first" : ""}`}
                  key={`list-item-${index}`}
                  to={`/works${work.uri}`}
                  activeStyle={{ color: "#BCBCBC" }}
                  partiallyActive={true}
                >
                  <span className="span-container">
                    <span>
                      {work.project.prepend !== null
                        ? work.project.prepend
                        : work.date}{" "}
                    </span>
                    <span>{work.title}</span>
                  </span>

                  {currentIndex === index && (
                    <div className="tags">
                      {work.project.labels !== null &&
                        work.project.labels.map((element, index) => {
                          return (
                            <div key={`label-${index}`}>
                              {element.label}
                              {index < work.project.labels.length - 1 && ","}
                              &nbsp;
                            </div>
                          )
                        })}
                    </div>
                  )}
                </Link>
              )
            })}
          </div>
        </div>
        {width < 768 && (
          <div className="mobile-aux-links">
            <Link to="/contacts">About</Link>
            <a href="https://www.instagram.com/francescotosini_/">IG</a>
          </div>
        )}
      </motion.div>
    </>
  )
}

export default Sidebar
