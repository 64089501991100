import React, { useState, useEffect } from "react"
import Sidebar from "./WoksSidebar/Sidebar"
import { AnimatePresence, motion } from "framer-motion"
import useWindowSize from "../../hooks/useWindowSize"
import WorkNavbar from "./WorkNavbar/WorkNavbar"
import { useOrderedWpPostsQuery } from "../../hooks/useOrderedWpPosts"
import { Link } from "gatsby"
import _ from "lodash"
import { isMobile } from "react-device-detect"

const movements = {
  initial: {
    opacity: 0,
  },
  open: {
    opacity: 1,
  },
  close: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
}
const Layout = ({ children, location, pageContext }) => {
  const { width } = useWindowSize()
  const [sidebarState, setSidebarState] = useState(false)
  const [showTopNavbar, setShowTopNavbar] = useState(false)

  useEffect(() => {
    if (
      !location.pathname.includes("works") &&
      !location.pathname.includes("contacts")
    ) {
      setSidebarState(false)
      setShowTopNavbar(false)
    } else {
      setSidebarState(true)
      setShowTopNavbar(true)
    }
  }, [location.pathname])

  useEffect(() => {
    if (width > 768) {
      if (location.pathname !== "/") {
        setSidebarState(true)
      }
    } else {
      if (location.pathname === "/works/" || location.pathname === "/works") {
        setSidebarState(true)
      } else {
        setSidebarState(false)
      }
    }
  }, [width, location.pathname])

  useEffect(() => {
    isMobile && location.pathname === "/" && setSidebarState(true)
  }, [location.pathname])

  const data = useOrderedWpPostsQuery()
  const posts = data.wpPage.works.allProjects
  const [nextDest, setNextDest] = useState()
  let currentIndex = _.findIndex(
    posts,
    e => {
      return e.title === pageContext.title
    },
    0
  )
  useEffect(() => {
    setNextDest(currentIndex !== posts.length - 1 ? currentIndex + 1 : 0)
  }, [currentIndex, posts.length])

  useEffect(() => {
    if (isMobile && location.pathname === "/") {
      setSidebarState(true)
      setShowTopNavbar(true)
    }
  }, [location.pathname])

  return (
    <>
      <div className="main-container">
        {showTopNavbar && (
          <WorkNavbar
            title={pageContext.title}
            next={"/works" + posts[nextDest]?.uri}
            sidebarState={sidebarState}
            setSidebarState={setSidebarState}
          />
        )}
        <AnimatePresence>
          <Sidebar
            data={data}
            sidebarState={
              isMobile && location.pathname === "/" ? true : sidebarState
            }
            width={width}
            currentIndex={currentIndex}
          />
        </AnimatePresence>
        <AnimatePresence>
          {width > 768 ? (
            <motion.main
              className={`${location.pathname === "/" ? "splash-main" : ""}`}
              variants={movements}
              initial="initial"
              animate={!sidebarState ? "close" : "open"}
              exit="exit"
              transition={{ type: "tween", duration: 0.2 }}
            >
              {children}
            </motion.main>
          ) : (
            <motion.main
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {children}
            </motion.main>
          )}
        </AnimatePresence>
        {location.pathname !== "/" && (
          <div className="bottom-navbar">
            {width > 768 ? (
              <>
                <Link to="/contacts/">About</Link>
                <a href="https://www.instagram.com/francescotosini_/">IG</a>
              </>
            ) : (
              width < 768 && <></>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default Layout
