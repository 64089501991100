import React from "react"
import "./WorkNavbar.scss"
import { Link } from "gatsby"
import useWindowSize from "../../../hooks/useWindowSize"
import { isMobile } from "react-device-detect"

const WorkNavbar = ({ title, next, setSidebarState, sidebarState }) => {
  const { width } = useWindowSize()
  return (
    <div className="work-navbar">
      <div>
        {isMobile ? (
          <h2>Francesco Tosini</h2>
        ) : (
          <Link to="/" className="home-link">
            Francesco Tosini
          </Link>
        )}

        {width > 768 ? (
          <h2 className="mobile-right">Selected works</h2>
        ) : (
          <button
            className="mobile-sidebar-top-btn"
            onClick={() => setSidebarState(!sidebarState)}
          >
            Selected works
          </button>
        )}
      </div>
      {width > 768 && (
        <div>
          <h2>{title !== undefined ? title : ""}</h2>
          <button>
            <Link to={next}>Next</Link>
          </button>
        </div>
      )}
    </div>
  )
}

export default WorkNavbar
